<template>
  <div class="ats">
    <v-progress-linear
    indeterminate
    color="secondary"
    height="10"
    top
    :active="loaded"
    ></v-progress-linear>
    <v-container fluid>
      <overlay v-if="$store.state.hotelTimeOut && currentPage !== 3" />
      <div>
        <v-overlay :value="showOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
          {{ text }}
           <router-link to="/hotels" style="color: blue; margin-left: 5px;"> Search again</router-link>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="snackbar = false">mdi-close</v-icon>
          </v-row>
        </v-snackbar>
        <div v-if="!loaded && !dataReady">
          <p class="font-weight-bold body-1 text-center">The room or rooms you want aren't available any more <small class="body-1 blue--text" id="returnBtn" @click="$router.push({ name: 'hotels' })">Search again</small></p>
        </div>
        <div v-if="!loaded && dataReady">
          <v-container fluid v-if="availability.AvailableForBook">
             <v-stepper class="elevation-0" v-model="el">
              <v-stepper-header class="elevation-0">
                <v-stepper-step
                :complete="el > 1"
                :editable="el === 2"
                :step="1"
                color="blueDark"
                >
                  Review
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el > 2"
                :step="2"
                color="blueDark"
                >
                  Guests
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                :complete="el === 3"
                :step="3"
                color="blueDark"
                >
                  Confirmation
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                  <v-stepper-content :step="1">
                    <v-row>
                      <v-col cols="12" md="8" lg="9">
                        <v-alert
                        border="left"
                        color="warning"
                        dark
                        v-model="alert"
                        dismissible
                        >
                          {{alertText}}
                        </v-alert>
                        <v-card class="pa-5" outlined>
                          <v-row no-gutters>
                            <h3 class="font-weight-bold">{{hotel.HotelName}}</h3>
                            <v-rating :length="hotel.HotelStars" color="warning" readonly small :value="Number(hotel.HotelStars)" full-icon="mdi-star"></v-rating>
                          </v-row>
                          <v-row justify="space-between" no-gutters>
                            <p><v-icon small class="mr-1" color="secondary">mdi-phone</v-icon>{{hotel.HotelPhone}}</p>
                            <p><v-icon small class="mr-1 ml-1" color="secondary">mdi-fax</v-icon>{{hotel.HotelFax}}</p>
                          </v-row>
                          <p class="body-2 mb-0 mt-1">
                            <v-icon small class="mt-0" color="secondary">mdi-map-marker</v-icon>
                            {{hotel.HotelAddress}},{{hotel.HotelCity}},{{hotel.HotelCountry}}
                          </p>
                          <v-btn text color="blue" small @click="openMap(hotel.map)">
                            Show on map
                          </v-btn>
                        </v-card>
                        <v-card outlined class="pa-5 my-5">
                          <p class="body-1 font-weight-bold mt-5">Hotel policy</p>
                          <div v-for="(policy, n) in availability.HotelPolicies" :key="n">
                            <div v-text="policy"></div>
                          </div>
                        </v-card>
                        <v-card outlined class="pa-5">
                          <p class="body-1 font-weight-bold mt-5">Cancelation</p>
                          <p class="body-2">{{availability.DefaultPolicy}}</p>
                          <p v-if="availability.lastCancellationDeadLine">Cancellation DeadLine: {{formatDate(availability.lastCancellationDeadLine)}}</p>
                          <div v-if="availability.cancelPolicies && availability.cancelPolicies.length > 0">
                              <p v-for="(policy, i) in availability.cancelPolicies" :key="i">{{policy.roomType}} From {{formatDate(policy.fromDate)}} to {{formatDate(policy.toDate)}} cancelation charge {{policy.chargeType === 'Fixed' ? '$' : '%' }}{{policy.cancellationCharge}}</p>
                          </div>
                        </v-card>
                        <v-checkbox color="blueDark" id="book-terms-checkbox" v-model="acceptCancellation" label="I Accept cancellation policies"></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="4" lg="3">
                        <v-card outlined class="mb-5">
                          <v-card-text class="pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="body-1">Room(s) price</p>
                              <p class="body-1">{{changeRoomsPrice().base | changeCurrency(changeRoomsPrice().base)}}</p>
                            </v-row>
                            <v-row no-gutters justify="space-between">
                              <p class="body-1">Total Taxes</p>
                              <p class="body-1">{{changeRoomsPrice().tax | changeCurrency(changeRoomsPrice().tax)}}</p>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="blueDark white--text pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="text-h5 font-weight-bold">Grand Total</p>
                              <p class="text-h5 font-weight-bold">{{changeRoomsPrice().price | changeCurrency(changeRoomsPrice().price)}}</p>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn
                    color="blueDark white--text"
                    @click="nextClicked(1)"
                    class="py-8"
                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                    >
                      Continue to Guests
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content :step="2">
                    <v-row>
                      <v-col cols="12" md="8" lg="9">
                        <v-card outlined class="pa-5">
                          <v-form v-model="valid" ref="form">
                            <p v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'" class="body-1 font-weight-bold secondary--text">Contact person details</p>
                            <v-row v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'">
                              <v-col cols="12" sm="6" class="py-0">
                                <phoneInput :phone="phone.nationalNumber" @update="assignPhone" />
                              </v-col>
                              <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                outlined
                                v-model="email"
                                :rules="[v => !!v || 'E-mail is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                                label="E-mail"
                                required
                                color="blue"
                                id="guest-email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-divider v-if="!$cookies.isKey('userToken') || $store.state.user.role === 'admin' || $store.state.user.role === 'subAgent'"></v-divider>
                            <div class="my-3" v-for="(room, i) in rooms" :key="i">
                              <p class="body-1 font-weight-bold text-decoration-underline secondary--text">Room {{i + 1}}</p>
                              <v-row v-for="n in $store.state.hotelSearch.guestsInfo[i].adults" :key="(n * 100) + previousNumber('adult', i)">
                                <v-col cols="12">Adult {{n}}</v-col>
                                <v-col cols="12" sm="2">
                                    <v-select
                                    v-model="guestTitles[(n - 1) + (i) + (previousNumber('adult', i))]"
                                    :items="['Mr', 'Mrs', 'Ms', 'Miss']"
                                    label="Title"
                                    outlined
                                    required
                                    :rules="[v => !!v || 'Item is required']"
                                    color="blue"
                                    :id="`room-${i}-adult-guest-${n}-title`"
                                    >
                                      <template v-slot:selection="{ item }">
                                        <span class="primary--text caption">{{item}}</span>
                                      </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                  outlined
                                  v-model="adultFirstNames[(n - 1) + (i) + (previousNumber('adult', i))]"
                                  :rules="nameRule"
                                  label="First name"
                                  required
                                  color="blue"
                                  autocomplete="off"
                                  :id="`room-${i}-adult-guest-${n}-fName`"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field
                                  outlined
                                  v-model="adultLastNames[(n - 1) + (i) + (previousNumber('adult', i))]"
                                  :rules="nameRule"
                                  label="Last name"
                                  required
                                  color="blue"
                                  autocomplete="off"
                                  :id="`room-${i}-guest-adult-${n}-lName`"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <v-row justify="space-around">
                                  <p class="ma-0 mt-3">Leader</p>
                                  <input type="radio"
                                  style="width: 19px; height: 50px;"
                                  v-model="isLeader"
                                  :value="(n - 1) + (i) + (previousNumber('adult', i))"
                                  >
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row v-for="n in $store.state.hotelSearch.guestsInfo[i].children" :key="(n) + previousNumber('child', i)">
                                  <v-col cols="12">Child {{n}}</v-col>
                                  <v-col cols="12" sm="4">
                                  <v-select
                                    v-model="childrenTitles[(n - 1) + (i) + (previousNumber('child', i))]"
                                    :items="['Mr', 'Miss']"
                                    label="Title"
                                    outlined
                                    required
                                    :rules="[v => !!v || 'Item is required']"
                                    color="blue"
                                    :id="`room-${i}-guest-child-${n}-title`"
                                    >
                                      <template v-slot:selection="{ item }">
                                        <span class="primary--text caption">{{item}}</span>
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                    outlined
                                    v-model="childrenFirstNames[(n - 1) + (i) + (previousNumber('child', i))]"
                                    :rules="nameRule"
                                    label="First name"
                                    required
                                    color="blue"
                                    autocomplete="off"
                                    :id="`room-${i}-guest-child-${n}-fName`"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4">
                                    <v-text-field
                                    outlined
                                    v-model="childrenLastNames[(n - 1) + (i) + (previousNumber('child', i))]"
                                    :rules="nameRule"
                                    label="Last name"
                                    required
                                    color="blue"
                                    autocomplete="off"
                                    :id="`room-${i}-guest-child-${n}-lName`"
                                    ></v-text-field>
                                  </v-col>
                              </v-row>
                            </div>
                          </v-form>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" lg="3">
                        <v-card outlined class="mb-5">
                          <v-card-text class="pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="body-1">Room(s) price</p>
                              <p class="body-1">{{changeRoomsPrice().base | changeCurrency(changeRoomsPrice().base)}}</p>
                            </v-row>
                            <v-row no-gutters justify="space-between">
                              <p class="body-1">Total Taxes</p>
                              <p class="body-1">{{changeRoomsPrice().tax | changeCurrency(changeRoomsPrice().tax)}}</p>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="blueDark white--text pa-5">
                            <v-row no-gutters justify="space-between">
                              <p class="text-h5 font-weight-bold">Grand Total</p>
                              <p class="text-h5 font-weight-bold">{{changeRoomsPrice().price | changeCurrency(changeRoomsPrice().price)}}</p>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn
                    color="blueDark white--text"
                    @click="nextClicked(2)"
                    class="my-5 py-8"
                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 75%;' : ($vuetify.breakpoint.md ? 'width: 66%;' : 'width: 100%;')"
                    >
                      Book
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content :step="3">
                    <h3 class="display-1 font-weight-regular my-5 blueDark--text text-center">Thank you for booking with us</h3>
                    <h3 class="display-1 font-weight-thin my-5 primary--text text-center">Your Details have been submitted successfully; An agent will contact you shortly to complete your booking!</h3>
                    <v-btn
                    color="blueDark white--text"
                    @click="closeTab"
                    class="py-8"
                    >
                      Done
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
          </v-container>
          <div v-else>
            <p class="font-weight-bold body-1 text-center">The room or rooms you want aren't available any more <small class="body-1 blue--text" id="returnBtn" @click="$router.go(-1)">Return</small></p>
          </div>
        </div>
      </div>
      <v-dialog
      v-model="dialog"
      max-width="80%"
      >
          <gmap-map
          :center="center"
          :zoom="16"
          style="width:100%;  height: 500px;"
          zoomControl
          class="mt-1"
          >
              <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center=m.position"
              :clickable="true"
              :draggable="true"
              ></gmap-marker>
          </gmap-map>
      </v-dialog>
      <v-dialog
      v-model="priceChangeDialog"
      max-width="500"
      persistent
      >
        <v-card>
          <v-card-title
            class="headline secondary"
            primary-title
          >
            Price Change
          </v-card-title>

          <v-card-text>
            Please note that some rooms' prices have been changeda and the new price is <span class="body-1 primary--text font-weight-bold"> {{getNewPrice()}}</span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="priceChangeDialog = false; $router.push({ name: 'hotelsResults' })"
            >
              Back To Results
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="priceChangeDialog = false; showOverlay = true; book()"
            >
              Book
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { bookHotel, hotelAvailability, headers, headersNoAuth } from '../links'
import overlay from '../components/overlay'
// import bookinData from '../components/bookingDataTable'
// import credit from '../components/credit'
import phoneInput from '../components/mobile.vue'
// import addressCard from '../components/address info.vue'

export default {
  title: '-Hotel Book',
  components: {
    overlay,
    // bookinData,
    // credit,
    phoneInput
    // addressCard
  },
  data () {
    return {
      el: 1,
      showAlert: true,
      loaded: true,
      dataReady: false,
      dialog: false,
      alert: false,
      paymentType: 'credit',
      acceptCancellation: false,
      alertText: '',
      currentPage: 0,
      searcTerms: {},
      hotel: {},
      rooms: [],
      zip: '',
      address: '',
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      nameRule: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      nextLabel: 'Continue',
      valid: true,
      valid1: true,
      snackbar: false,
      text: '',
      color: '',
      showOverlay: false,
      isLeader: 0,
      guestTitles: [],
      childrenTitles: [],
      adultFirstNames: [],
      adultLastNames: [],
      childrenFirstNames: [],
      childrenLastNames: [],
      email: '',
      phone: '',
      cardNum: '',
      cardCode: '',
      creditType: '',
      cardHolder: '',
      cardExpireDate: '',
      markers: [],
      center: {
        lat: 0,
        lng: 0
      },
      availability: {},
      menu: false,
      supplements: [],
      bookingNo: '',
      guestsData: [],
      priceChangeDialog: false,
      newPriceBody: {},
      // hotelsTimeOut: null,
      hotelsOverlay: false
    }
  },
  watch: {
    el (newVal) {
      if (newVal === 2) {
        document.getElementById('card-address').addEventListener('input', (e) => {
          this.$store.dispatch('setWrittenAddress', e.target.value)
        })
      }
    }
  },
  methods: {
    setAddress (address, zip) {
      this.address = address
      this.zip = zip
    },
    assignPhone (phone) {
      this.phone = phone
    },
    setCardData (number, type) {
      this.cardNum = number
      switch (type) {
        case 'visa':
          this.creditType = 'VI'
          break
        case 'mastercard':
          this.creditType = 'CA'
          break
        case 'american-express':
          this.creditType = 'AX'
          break
        case 'discover':
          this.creditType = 'DS'
          break
        case 'diners-club':
          this.creditType = 'DN'
          break
        default:
          this.creditType = type
          break
      }
    },
    setCardExpiry (date) {
      this.cardExpireDate = date
    },
    setCVV (cvv) {
      this.cardCode = cvv
    },
    setCardHolder (name) {
      this.cardHolder = name
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = day ? `${day} ${months[month - 1]} ${year}` : `${months[month - 1]} ${year}`
      return newDate
    },
    openMap (position) {
      this.dialog = !this.dialog
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    changeRoomsPrice () {
      let price = 0
      let tax = 0
      let base = 0
      if (this.availability.priceChanged === 'true') {
        for (let i = 0; i < this.rooms.length; i++) {
          this.rooms[i].rateSummary = this.availability.rooms[i].rateSummary
        }
        let text = 'Please notice that the rooms prices has changed as follows'
        this.rooms.forEach(room => {
          price = price + room.rateSummary.totalFare
          tax = tax + room.rateSummary.tax
          base = base + room.rateSummary.baseFare
        })
        for (let i = 0; i < this.rooms.length; i++) {
          text += ` room number ${i + 1} price is $${this.rooms[i].rateSummary.totalFare} `
        }
        this.alert = true
        this.alertText = text
      } else {
        this.rooms.forEach(room => {
          price = price + room.rateSummary.totalFare
          tax = tax + room.rateSummary.tax
          base = base + room.rateSummary.baseFare
        })
      }
      return { price: price.toFixed(2), tax: tax.toFixed(2), base: base.toFixed(2) }
    },
    getNewPrice () {
      let price = 0
      this.rooms.forEach(room => {
        price = price + room.rateSummary.totalFare
      })
      return price.toFixed(2)
    },
    previousNumber (type, i) {
      if (type === 'adult') {
        if (i > 0) {
          let number = 0
          for (let n = 0; n < i; n++) {
            number = number + this.$store.state.hotelSearch.guestsInfo[n].adults
          }
          return number
        } else return 0
      } else {
        if (i > 0) {
          let number = 0
          for (let n = 0; n < i; n++) {
            number = number + this.$store.state.hotelSearch.guestsInfo[n].children
          }
          return number
        } else return 0
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 1) {
          this.currentPage = 1
          this.snackbar = false
          if (this.acceptCancellation) {
            // return true
            this.el = 2
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please reead and accept on the cancllation policy to continue'
            return false
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          this.$refs.form.validate()
          if (this.valid) {
            this.showOverlay = true
            const fullNames = []
            for (let index = 0; index < this.adultFirstNames.length; index++) {
              if (this.adultFirstNames[index] && this.adultLastNames[index]) fullNames.push(`${this.adultFirstNames[index]} ${this.adultLastNames[index]}`)
            }
            for (let index = 0; index < this.childrenFirstNames.length; index++) {
              if (this.childrenFirstNames[index] && this.childrenLastNames[index]) fullNames.push(`${this.childrenFirstNames[index]} ${this.childrenLastNames[index]}`)
            }
            if (new Set(fullNames).size === fullNames.length) {
              if ((!this.$cookies.isKey('userToken') && this.phone.valid) || (this.$cookies.isKey('userToken') && this.$store.state.user.role !== 'customer' && this.phone.valid) || (this.$cookies.isKey('userToken') && this.$store.state.user.role === 'customer')) {
                this.snackbar = false
                this.prepareGuestsData()
                // this.el = 3
                this.book()
              } else {
                this.snackbar = true
                this.color = 'error'
                this.text = 'Please provide a valid phone number'
                return false
              }
            } else {
              this.snackbar = true
              this.color = 'warning'
              this.text = 'Please avoid using similar names for guests'
              return false
            }
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to cotinue'
            return false
          }
        }
        // } else if (currentPage === 3) {
        //   this.showAlert = false
        //   this.$router.push({ name: 'Home' })
        //   return true
        // } else {
        //   this.nextLabel = 'Continue'
        //   return true
        // }
        // else if (currentPage === 3) {
        //   this.currentPage = 3
        //   this.$refs.form1.validate()
        //   this.$refs.form.validate()
        //   this.nextLabel = 'Book'
        //   if (this.valid1) {
        //     this.snackbar = false
        //     this.showOverlay = true
        //     if (!this.address && this.$store.state.writtenAddress) this.address = this.$store.state.writtenAddress
        //     if (!this.zip && this.$store.state.zipCode) this.zip = this.$store.state.zipCode
        //     if (this.address) {
        //       if (this.zip) {
        //         this.book()
        //       } else {
        //         this.snackbar = true
        //         this.color = 'error'
        //         this.text = 'Please Enter your ZIP code'
        //       }
        //     } else {
        //       this.snackbar = true
        //       this.color = 'error'
        //       this.text = 'Please Enter your address'
        //       document.getElementsByClassName('address-autocomplete')[0].style.borderColor = 'red'
        //     }
        //     // this.book()
        //   } else {
        //     this.snackbar = true
        //     this.color = 'warning'
        //     this.text = 'Please fill all fields to Book'
        //     return false
        //   }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 3) {
        return false
      } else if (currentPage === 2) {
        this.nextLabel = 'Continue'
        return true
      } else return true
    },
    prepareGuestsData () {
      this.guestsData = []
      for (let i = 0; i < this.$store.state.hotelSearch.guestsInfo.length; i++) {
        for (let n = 0; n < this.$store.state.hotelSearch.guestsInfo[i].adults; n++) {
          const guest = {
            isLead: this.isLeader === ((this.previousNumber('adult', i)) + i + n) ? 1 : 0,
            title: this.guestTitles[(this.previousNumber('adult', i)) + i + n],
            firstName: this.adultFirstNames[(this.previousNumber('adult', i)) + i + n],
            lastName: this.adultLastNames[(this.previousNumber('adult', i)) + i + n],
            guestType: 'Adult',
            InRoom: i + 1
          }
          this.guestsData.push(guest)
        }
        for (let n = 0; n < this.$store.state.hotelSearch.guestsInfo[i].children; n++) {
          const guest = {
            isLead: 0,
            title: this.childrenTitles[(this.previousNumber('child', i)) + i + n],
            firstName: this.childrenFirstNames[(this.previousNumber('child', i)) + i + n],
            lastName: this.childrenLastNames[(this.previousNumber('child', i)) + i + n],
            guestType: 'Child',
            InRoom: i + 1,
            childAge: this.$store.state.hotelSearch.guestsInfo[i].childrenAges[n]
          }
          this.guestsData.push(guest)
        }
      }
    },
    book () {
      const body = {
        guestNationality: this.$store.state.countryCode,
        sessionId: this.searcTerms.sessionId,
        numberOfRooms: this.rooms.length,
        hotelIndex: this.searcTerms.hotelIndex,
        hotelCode: this.searcTerms.hotelCode,
        hotelName: this.hotel.HotelName,
        // creditCardInfo: {
        //   card_number: this.cardNum,
        //   card_expiry_date: this.cardExpireDate,
        //   card_code: this.cardCode,
        //   card_type: this.creditType,
        //   cardHolderName: this.cardHolder,
        //   zipCode: this.zip,
        //   address: this.address
        // },
        lastCancellationDeadLine: this.availability.lastCancellationDeadLine,
        // phoneNumber: this.phone,
        // email: this.email,
        guests: this.guestsData,
        rooms: []
      }
      // add room supplements to rooms & add rooms to body
      for (let i = 0; i < this.rooms.length; i++) {
        const supplements = []
        if (this.rooms[i].supplements) {
          this.rooms[i].supplements.forEach(sup => {
            if (sup.isMandatory) {
              supplements.push({
                id: sup.id,
                chargeType: sup.chargeType,
                price: sup.price,
                isSelected: 1
              })
            } else {
              this.supplements.forEach(selectedSup => {
                if (sup.id === selectedSup.id && this.rooms[i].roomIndex === selectedSup.room) {
                  selectedSup.accept ? supplements.push({
                    id: sup.id,
                    chargeType: sup.chargeType,
                    price: sup.price,
                    isSelected: 1
                  }) : supplements.push({
                    id: sup.id,
                    chargeType: sup.chargeType,
                    price: sup.price,
                    isSelected: 0
                  })
                }
              })
            }
          })
        }
        const room = {
          roomType: this.rooms[i].roomName,
          roomIndex: this.rooms[i].roomIndex,
          roomCode: this.rooms[i].roomCode,
          ratePlanCode: this.rooms[i].ratePlanCode,
          roomFare: this.rooms[i].rateSummary.baseFare,
          currency: this.rooms[i].rateSummary.currency,
          roomTax: this.rooms[i].rateSummary.tax,
          totalFare: this.rooms[i].rateSummary.totalFare,
          supplements: supplements
        }
        body.rooms.push(room)
      }
      if (this.paymentType !== 'credit') {
        delete body.creditCardInfo
        if (this.paymentType === 'points') body.redeem = 'redeem'
        else if (this.paymentType === 'cash') {
          body.cash = 'cash'
        }
      }
      if ((this.$cookies.isKey('userToken') && this.$store.state.user.role !== 'customer') || !this.$cookies.isKey('userToken')) {
        body.phoneNumber = this.phone.nationalNumber
        body.countryIsoCode = this.phone.countryCode
        body.email = this.email
      }
      let header
      if (this.$cookies.get('userToken')) header = headers(this.$cookies.get('userToken'))
      else header = headersNoAuth()
      this.$http.post(bookHotel, body, { headers: header }).then(response => {
        if (response.body.status === false) {
          this.showOverlay = false
          this.text = response.body.message
          this.color = 'error'
          this.snackbar = true
          this.disableBookingBtn()
        } else if (response.body.status === 406) {
          this.showOverlay = false
          this.priceChangeDialog = true
          this.rooms = response.body.data.NotBooked.rooms
        } else {
          this.showOverlay = false
          this.bookingNo = response.body.data.BookingNo
          this.$store.dispatch('setHotelTimeOut', false)
          if (this.$store.state.hotelTimeOutFunction) this.$store.dispatch('clearHotelTimeoutFunction')
          if (this.$store.state.hotelSearchTimeOutFunction) this.$store.dispatch('clearHotelSearchTimeoutFunction')
          // document.querySelector('.wizard__body__actions a[data-v-c21d83ca]').style.display = 'none'
          // this.nextLabel = 'Finish'
          // this.currentPage = 3
          this.el = 3
        }
      }, () => {
        this.showOverlay = false
        this.text = 'Something went wrong, please try again in a few minutes'
        this.color = 'error'
        this.snackbar = true
      }).catch((e) => {
      })
    },
    closeTab () {
      window.close()
    },
    disableBookingBtn () {
      const btns = document.getElementsByClassName('wizard__next pull-right')
      btns.forEach(btn => {
        btn.style.display = 'none'
      })
      setTimeout(() => {
        btns.forEach(btn => {
          btn.style.display = 'block'
        })
      }, 30000)
    }
  },
  created () {
    window.scrollTo(0, 0)
    // this.hotelsTimeOut = setTimeout(() => {
    //   this.hotelsOverlay = true
    // }, 20 * 60 * 1000)
    this.searcTerms = this.$route.params
    if (this.searcTerms.hotel) {
      this.hotel = this.searcTerms.hotel
      this.rooms = this.searcTerms.rooms
      this.supplements = this.searcTerms.supplements
      let term = `sessionId=${this.searcTerms.sessionId}&hotelIndex=${this.searcTerms.hotelIndex}&hotelCode=${this.searcTerms.hotelCode}`
      if (this.searcTerms.rooms) {
        const rooms = this.searcTerms.rooms.sort((a, b) => { return a.roomIndex - b.roomIndex })
        rooms.forEach(room => {
          term = term + `&roomIndex[]=${room.roomIndex}`
        })
        this.$http.get(hotelAvailability(term), { headers: headersNoAuth() }).then(res => {
          if (res.body.status === false) {
            this.loaded = false
            this.dataReady = false
          } else {
            this.loaded = false
            this.dataReady = true
            this.availability = res.body.data
            this.$store.dispatch('setHotelTimeOut', false)
            document.body.style.height = '100%'
            this.$store.dispatch('setHotelTimeOutFunction', setTimeout(() => {
              this.$store.dispatch('setHotelTimeOut', true)
            }, 20 * 60 * 1000)
            )
          }
        }, () => {
          this.loaded = false
          this.dataReady = false
        })
      } else {
        this.loaded = false
        this.dataReady = false
      }
    } else {
      this.loaded = false
      this.dataReady = false
    }
  },
  beforeDestroy () {
    clearTimeout(this.hotelsTimeOut)
    this.hotelsOverlay = false
  }
}
</script>

<style>
   #returnBtn:hover {
    cursor: pointer;
   }
  .ats .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .fare .wizard__body__actions a[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__body__actions[data-v-c21d83ca] {
    background-color:white !important;
    border-top: 0.1px solid #e3e3e3 !important;
  }
  .wizard__body[data-v-c21d83ca] {
    margin-top: 30px !important;
    min-height: auto !important;
    margin-left: 20px !important;
    margin-right: 10px !important;
    border: none !important;
    box-shadow: 1px 3px 3px gray !important;
  }
  .ats .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .ats .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#150958 !important;
  }
  .fare .wizard__step.active .wizard__step__indicator[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .fare .wizard__step.active:not(:first-child) .wizard__step__line[data-v-c21d83ca] {
    background-color:#DF731C !important;
  }
  .wizard__arrow[data-v-c21d83ca] {
    border: none !important;
  }
  .remove-controls input[type='number'] {
    -moz-appearance:textfield;
  }
  .remove-controls input::-webkit-outer-spin-button,
  .remove-controls input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
</style>
